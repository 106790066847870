<template lang="pug">
.Help.Container(:style="isAtril ? 'color: #545454; max-width: 1012px; margin: auto; height: calc(100vh - 173px);' : 'color: #545454; font-size: 1.3rem;'")
  .Overlay
  .Content(:style="isAtril ? 'width: 1012px;' : ''")
    div(style="text-align: center;").mt-3
      div.text-center.dk-othre.d-flex.justify-content-center(style="flex-direction: column; opacity: 0.7" :style="isAtril ? 'align-items: center;' : ''").px-4.pt-2.mx-3
        b(:style="isAtril ? 'max-width: fit-content; font-size: 40px;' : ''").m-0 ¡Hola {{ nameUser}}!
        p(:style="isAtril ? 'max-width: fit-content; font-size: 40px;' : ''").m-0 Te damos la Bienvenida a nuestro Centro Médico
        p(:style="isAtril ? 'max-width: fit-content; font-size: 40px;' : ''").m-0 La atención es para el documento de identificacion:
        p(:style="isAtril ? 'max-width: fit-content; font-size: 40px;' : ''").m-0 {{ tipoIdentificacionUser }} {{ identificacionUser }}
    div(:style="isAtril ? 'display: flex; padding-bottom: 30px;' : ''")
      b-button.mt-4(variant="bluecolmedica" @click="cleanAndBack" :style="isAtril ? 'font-size: 1.7rem; margin: 0 auto; display: block; width: 100%; max-width: 350px;' + colorComputed : 'width: 100%; max-width: 250px; font-size: 18px !important; margin: 20px auto; display: block;' + colorComputed") Digitar nuevos datos
      b-button.mt-4(
        variant="bluecolmedica",
        @click="goUserView",
        :style="isAtril ? 'font-size: 1.7rem; margin: auto; display: block; width: 100%; max-width: 410px;' + colorComputed : 'font-size: 18px !important; max-width: 250px; width: 100%; margin: auto; display: block; margin-bottom: 3rem; ' + colorComputed"
      )
        div(v-if="!!!charging")
          span(style="font-size: 18px;" :style="isAtril ? 'font-size: 30px;' : ''")
            | Continuar con estos datos
          //- span
          //-   Continue(size="24px")
        div(v-else)
          MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";

import HelpIcon from "mdi-vue/HelpCircle.vue";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";

export default {
  watch: {
    currentUser(val) {
      if (!val) this.goBack();
    }
  },

  components: {
    Header,
    HelpIcon,
    Continue,
    MiniSpinner
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrow.currentUser,
      charging: state => state.virtualrow.charging,
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      citas: state => state.virtualrow.citas,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor
    }),
    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },
    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },
    siteIsOffice() {
      return this.currentSite?.type === "of";
    },
    siteName() {
      return this.currentSite?.name;
    },
    nameUser() {
      return this.currentUser?.Nombres
    },
    identificacionUser() {
      return this.currentUser?.NumeroIdentificacion
    },
    tipoIdentificacionUser() {
      return this.currentUser?.TipoIdentificacion
    },
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      cleanCurrentUser: "virtualrow/cleanCurrentUser"
    }),

    cleanAndBack() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
      this.goBack();
    },

    goUserView() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if(this.citas && this.citas.length) {
        this.goToView({ view: "Quote" });
      } else {
        this.goToView({ view: "NoDate" });
      }
    }
  }
};
</script>

<style scoped>
.Help {
  position: fixed;
  inset: 0;
  z-index: 50;
  opacity: 0;
  transition: 0.2s opacity;
  animation: 0.2s forwards fadeIn ease-in-out;
}

.Content {
  position: fixed;
  z-index: 31;
  background: white;
  border-radius: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
}

.Overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 30;
  color: rgb(0, 0, 0) !important
}

.CurrentUser > div {
  display: table-cell;
  vertical-align: middle;
}

.gray {
  color: gray;
}

.big_box {
  position: relative;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-font {
  font-size: 1.3rem;
}

.dk-pos-dk {
  position: absolute;
  top: -45%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-size: 2.8rem;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
</style>
