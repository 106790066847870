<template lang="pug">
.Container(
  :class="!!!turn ? 'container-logo pb-1' : 'dk-padd pb-1'"
  :style="isAtril ? 'color: #545454; display: flex; flex-direction: column; justify-content: flex-start;' : 'display: flex; flex-direction: column; justify-content: center; font-size: 1.3rem; font-weight: bold; height: calc(100vh - 102.9px);'"
)
  .Content
    div.m-auto(v-if="stage != 0" :style="isAtril ? 'display: flex; justify-content: center; flex-direction: column; align-items: center;' : ''")
      .h(
        :class="isAtril ? 'h1 blue-text mt-3' : 'h1-mobile blue-text'" 
        :style="isAtril ? 'font-weight: 900 !important;' : 'max-width: 300px; display: block; margin: auto; text-align: center;'"
      ) {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
      p.mt-3.box-red.mb-5(v-if="isAtril") {{ actualTime() }}
    audio#attention(@ended="soundEmmited")
      source(src="@/assets/sounds/campanitas.mp3" type="audio/mpeg")
    audio#aproximate
      source(src="@/assets/sounds/turno_audio.mp3" type="audio/mpeg")
    div(
      v-if="stage === -1"
      style="position: absolute; margin-top: 30vh;"
      :style="isAtril ? '' : 'padding-bottom: 100px;'"
    )
      span(style="text-align: center")
        button.btn.btn-info(
          @click="startTurn"
          :style="isAtril ? 'font-size: 1.5rem; width: 100%; display: inline-block;' : 'width: 100%; display: inline-block;'"
        ) Reintentar
      h5.mt-3(
        :style="isAtril ? 'font-size: 2rem;text-align: center; color: firebrick;' : 'text-align: center; color: firebrick;'"
      ) No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico.

    .d-inline.text-center(v-if="stage === 0" :style="isAtril ? 'position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);' : 'padding-bottom: 100px; position: relative;'")
      img(:src="Conected" class="mx-auto" :class="isAtril ? 'size_icon--atril' : 'size-icon'" :style="isAtril ? '' : 'width: 78px;'")
      slot(name="enqueue")
      h5(
        :style="isAtril ? 'font-size: 2rem; font-weight: bold !important; color: #545454;' : ' font-weight: bold !important; color: #545454;'"
      ) Estamos solicitando tu turno. Por favor espera.

    //- p(v-if="currentUser.Tipo === 'P'" :class="isAtril && 'mt-5 mb-1'" :style="isAtril ? 'max-width: 1267px; margin: 0 auto; font-size: 2.5rem; padding: 0 15px; line-height: 1; font-weight: 500 !important; color: black;' : 'color: black; font-weight: 500 !important;'")
    //-   span Te hemos asignado el trámite {{ turn.jsonDetails.queue.name }} en el turno
    //-   strong.ml-1(:style="isAtril ? 'font-size: 2.5rem; font-weight: 700 !important; color: #91c9ed;' : 'color: #91c9ed; font-weight: 700 !important;'") {{ turnCommon.myTurn }}
    //-   span , por favor toma el
    div(v-if="stage === 1" :class="isAtril && 'box-flex-main mt-0'" :style="isAtril ? '' : 'padding-bottom: 100px;'")
      slot(name="waiting" v-bind:turn="turnCommon")
      .card-priority(:style="isAtril ? 'width: 836px; margin-left: 200px; min-height: 372px;' : ''")
        .d-flex.justify-content-center.align-items-center.header-py
        ul(style=`
          list-style: none;
          text-align: start;
          margin: 0;
        ` v-if="!isAtril")
          li(style="display:flex;padding: 10px 0 20px 0px;justify-content: space-between; align-items: center;" :style="isAtril ? 'justify-content: center;' : ''").px-3
            img(src="../../assets/images/doc.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'")
            .pad(:style="isAtril ? 'display: flex; gap: 5px; align-items: center;' : ''")
              p.mb-2.font-lighter(:style="isAtril ? 'font-size: 35px;' : ''") Consulta programada:
              p.m-0.font-lighter: strong(:style="isAtril ? 'font-size: 35px;' : ''") {{ turn.jsonDetails.queue.name }}
          li(style="display:flex;padding: 10px 0 20px 0px;justify-content: space-between; align-items: center;" :style="isAtril ? 'justify-content: center;' : ''").px-3
            img(src="../../assets/images/time.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'")
            .pad.pr-3.mr-3.d-flex.align-items-center.justify-content-center(v-if="!isAtril")
              p.m-0(:style="isAtril ? 'font-size: 35px;' : ''") Tiempo de espera:
              strong.ml-1(:style="isAtril ? 'font-size: 35px;' : ''") {{ calculatedMinutes(turn) }} minuto{{ calculatedMinutes(turn) == 1 ? "" : "s" }}
          li(v-if="priority" style="display:flex;padding: 10px 0 20px 0px;justify-content: space-between; align-items: center;" :style="isAtril ? 'justify-content: center;' : ''").px-3
            img(src="../../assets/images/wheelchair.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'")
            .pad.pr-3.mr-3: strong(:style="isAtril ? 'font-size: 35px;' : ''") Turno preferencial
        .d-flex.justify-content-center.py-3(v-if="isAtril" :style="isAtril ? siteIsOffice === true ? 'height: 332px;' : 'height: 402px;' : ''")
          ul.d-flex(style="flex-direction: column; justify-content: space-around; gap: 32px; margin: 0 !important;")
            img(src="../../assets/images/doc.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'")
            img(src="../../assets/images/wheelchair.svg" v-if="priority" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'")
            img(src="../../assets/images/time.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'" v-if="siteIsOffice !== true")
            img(src="../../assets/images/time.svg" :class="isAtril ? 'size_icon-atril' : 'size_icon-mobile'" :style="isAtril ? 'opacity: 0;' : ''")
          ul(style=`
            list-style: none;
            text-align: start;
            margin: 0px;
            gap: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          `)
            li(style="display:flex;padding: 0;justify-content: space-between; align-items: center;" :style="isAtril ? 'justify-content: center; height: 70px;' : ''").px-3
              .pad(:style="isAtril ? 'display: flex; gap: 5px; align-items: center;' : ''")
                p.m-0(:style="isAtril ? 'font-size: 35px;' : ''") Trámite:
                p.m-0: strong(:style="isAtril ? 'font-size: 35px;' : ''") {{ turn.jsonDetails.queue.name }}
            li(v-if="priority" style="display:flex;padding: 0;justify-content: space-between; align-items: center;" :style="isAtril ? 'justify-content: center; height: 70px;' : ''").px-3
              .pad: strong(:style="isAtril ? 'font-size: 35px;' : ''") Turno preferencial
            li(style="display:flex;padding: 0;justify-content: space-between; align-items: center; flex-direction: column" :style="isAtril ? 'justify-content: center; height: 70px;' : ''" v-if="siteIsOffice !== true").px-3
              .pad.d-flex.align-items-center.justify-content-center
                p.m-0(:style="isAtril ? 'font-size: 35px;' : ''") Tiempo de espera:
                strong.ml-1(:style="isAtril ? 'font-size: 35px;' : ''") {{ calculatedMinutes(turn) }} minuto{{ calculatedMinutes(turn) == 1 ? "" : "s" }}
            li(v-if="isAtril" style="display:flex;padding: 0;gap: 10px;justify-content: space-between; align-items: center; flex-direction: column" :style="isAtril ? 'justify-content: center; height: 70px;' : ''").px-3
              .pad.d-flex.align-items-center.justify-content-center
                p.m-0(:style="isAtril ? 'font-size: 35px;' : ''"): strong(style="color: #ff0f0f") Imprimiendo turno
      .pt-5(v-if="isAtril")
      template(v-if="!onlyGenerate")
        //- .mt-3
        //-   b-form-checkbox.audioNotification(
        //-     :class="{'isDisabled': !audioNotification, 'isEnabled': audioNotification}"
        //-     id="checkbox-1"
        //-     v-model="audioNotification"
        //-     name="checkbox-1"
        //-   )
        //-     span(style="font-size: 16px;") Notificarme con audio cuando sea atendido
        div(class="div_center_button" :style="hideVideo ? `margin-top: 2rem;` : ``")
          .mb-4.color-colmedica-light(
            v-if="status === 'ABSENT'"
          ) El turno fue marcado como 'Ausente'. Debe dirigirse a un asesor para que puedan volver a llamarlo.
          b-button(variant="bluecolmedica" @click="goHome" style="cursor: pointer;width: fit-content;").mt-3 Volver al inicio
      .overlay-m-c(v-if="showModalCancel")
      .modal-content-c(v-if="showModalCancel")
        .modal-c-c(:style="isAtril ? '' : 'transform: scale(0.85)'")
            p.modal-title(:style="isAtril ? '' : 'font-size: 30px'") Tu turno ha sido cancelado
      b-modal(v-model="showModal" :size="isAtril ? 'lg' : 'sm'" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
        div(class="p-2 position-relative w-auto h-auto" :class="isAtril ? 'px-5 py-3' : ''")
          div(class="d-block text-center modal_inside" ).text-modals
            .special-border(v-if="isAtril")
            img(:src="AlertSrc" alt="alert" :style="isAtril ? 'width: 80px; height: 100px; margin-top: 20px' : ''")
            p(class="my-4" :style="isAtril ? 'font-size: 35px; font-weight: 900; color: #055BA4; line-height: 40px; padding-bottom: 20px;' : ''") Estás a punto de cancelar la solicitud
            p(class="my-4" :style="isAtril ? 'font-size: 25px; color: #393939;' : ''") Esto cancelará el proceso de atención con el asesor {{ comp }}
            p(class="my-4" :style="isAtril ? 'font-size: 25px; color: #393939; padding-top: 40px;' : 'font-size: 20px;'"): strong ¿Qué deseas hacer?
          div(class="position-relative" :class="isAtril ? 'd-flex justify-content-center align-items-center' : ''" :style="isAtril ? 'gap: 10px' : ''")
            b-button(@click="goHome" variant="bluecolmedica" class="btn-block cancelTurnConfirmation")
              span(:style="isAtril ? 'font-size: 25px;' : 'font-size: 18px;'") Volver al inicio
            b-button(@click="showModal = false" variant="bluecolmedica" :style="isAtril ? 'margin: 0;' : ''" class="btn-block")
              span(:style="isAtril ? 'font-size: 25px;' : 'font-size: 18px;'") Continuar con el asesor
    b-button(variant="bluecolmedica" @click="goHome" style="cursor: pointer;width: fit-content; font-size: 30px;" v-if="isAtril && stage === 1").mt-4.px-5 Volver al inicio
    div(v-if="stage === 2")
      img(:src="Altavoz" alt="altavoz" v-if="!siteIsOffice" style="max-width: 66px; margin: 25px 0;")
      img(src="../../assets/bell.png" alt="bell" v-else)
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : 'font-weight: bold !important; color: #545454;'"
      ) Tu número
      h4.text-middle.color-colmedica-light.font-weight-bold(
        :style="isAtril ? 'font-size: 2rem; !important; font-weight: bold;' : ' font-weight: bold !important; margin-bottom: 0;'"
      ) {{ myTurn }}
      h5.mt-4.text-middle.color-colmedica-gray(
        :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
      ) Ha sido llamado
      //- ********** Modificacion temporal mientras se logra obtener el nombre del puesto de api mobile
      template(v-if="attentionModule")
        h5.mt-4.text-middle.color-colmedica-gray.font-weight-bold(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': 'font-weight: bold !important; color: rgb(84, 84, 84); max-width: 187px; margin: 15px auto; font-size: 1.3rem; line-height: 25px;'"
        ) Por favor acércate al módulo de atención
        h4.text-middle.color-colmedica-light.font-weight-bold {{ attentionModule }}
        h5.mt-4.text-middle.color-colmedica-light(
          style="font-weight: bold !important; color: #545454;"
        ) Serás atendido por
          h4.ml-2.color-colmedica-light.mt-1
            strong {{ attentionAgent }}
      template(v-else)
        h5.mt-4.text-middle(
          :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;': ' font-weight: bold !important; color: #545454;'"
        ) Por favor procede al módulo que se indica en la pantalla de TV

    //- div(v-if="stage === 3")
    //-   img(:src="OkSrc" alt="ok" style="width: 110px; margin-top: 50px;" v-if="isAtril")
    //-   h5.mt-2.text-middle.color-colmedica-gray(
    //-     :style="isAtril ? 'font-size: 80px; color: #055BA4 !important; font-weight: bold; margin-bottom: 150px;' : ' font-weight: bold !important; color: #545454; padding-top: 23px;'"
    //-   ) Tu turno ha finalizado.
    //-   h5.mt-2.text-middle.color-colmedica-gray(
    //-     :style="isAtril ? 'font-size: 35px; color: #00B5FC !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454; padding-bottom: 23px;'"
    //-   ) ¡Te esperamos en una próxima ocasión!
    //-   slot(name="endInfo")
    //-     h6.mt-4.text-middle.color-colmedica-gray(
    //-       v-if="!isAtril"
    //-       :style="isAtril ? 'font-size: 2rem; color: #545454 !important; font-weight: bold;' : ' font-weight: bold !important; color: #545454;'"
    //-     ) Ya puedes cerrar esta ventana
  

  //- .buttons.Footer
  //-   .d-flex(:class="isAtril ? 'justify-content-start' : 'justify-content-between'")
  //-     b-button(variant="whitecolmedica" :class="isAtril ? 'atril-btn-back' : ''" class="text-center" size="sm" type="button" @click="goBack")
  //-       svg(v-if="!isAtril" width='25' height='15' viewbox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg')
  //-         path(d='M25 6.25H5.31944L10.2917 1.7625L8.33333 0L0 7.5L8.33333 15L10.2917 13.2375L5.31944 8.75H25V6.25Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
  //-       svg(v-else width='58' height='35' viewbox='0 0 58 35' fill='none' xmlns='http://www.w3.org/2000/svg')
  //-         path(d='M57.95 14.522H12.4635L23.9555 4.15114L19.4293 0.0779114L0.168945 17.4108L19.4293 34.7437L23.9555 30.6705L12.4635 20.2996H57.95V14.522Z' fill='#F24E1E' style='fill:#F24E1E;fill:color(display-p3 0.9490 0.3059 0.1176);fill-opacity:1;')
  //-     b-button(v-if="!isAtril" variant="whitecolmedica" class="text-center" size="sm" type="button" @click="goHome")
  //-       svg(width='22' height='19' viewbox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg')
  //-         path(d='M21.5447 9.34433L11.5489 0.208078C11.4769 0.142118 11.3914 0.0897877 11.2973 0.054083C11.2032 0.0183783 11.1023 0 11.0005 0C10.8986 0 10.7977 0.0183783 10.7036 0.054083C10.6095 0.0897877 10.524 0.142118 10.452 0.208078L0.456229 9.34433C0.165019 9.6107 0 9.97251 0 10.3499C0 11.1334 0.696477 11.7705 1.55312 11.7705H2.60633V18.2897C2.60633 18.6826 2.95335 19 3.38289 19H9.44734V14.0279H12.1653V19H18.618C19.0476 19 19.3946 18.6826 19.3946 18.2897V11.7705H20.4478C20.8603 11.7705 21.2559 11.6217 21.5471 11.3532C22.1514 10.7982 22.1514 9.89926 21.5447 9.34433Z' fill='#FC0D0E' style='fill:#FC0D0E;fill:color(display-p3 0.9882 0.0510 0.0549);fill-opacity:1;')
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import LogoColmedica from "@/assets/colmedica_logo.png";
import Conected from "@/assets/conected.gif";
import Altavoz from "@/assets/altavoz.png";
import AlertSrc from "@/assets/images/alert.png";
import OkSrc from "@/assets/images/ok.png";

export default {
  name: "Turno",

  data: () => ({
    stage: 0,
    LogoColmedica,
    AlertSrc,
    OkSrc,
    Conected,
    Altavoz,
    errorCounter: 0,
    audioNotification: true,
    showModal: false,
    timerSound: null,
    soundEnded: false,
    showModalCancel: false
  }),

  mounted() {
    if (this.currentUser.TipoIdentificacion === "RC" && this.isAtril) {
      if (this.isAtril) {
        document.body.style.backgroundImage =
          "url(" + require("@/assets/images/foca_atril.png") + ")";
      } else {
        document.body.style.backgroundImage =
          "url(" + require("@/assets/images/yirafa.png") + ")";
      }
    }
  },

  created() {
    this.startTurn();

    setTimeout(() => {
      let origin = "fv_";
      if (location.hash.includes("fv_atril")) origin = "fv_atril_";
      // tracking
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-turno-asignado",
        debmedia_turn_code: this.turn.code,
        url_origen: window.location.href,
        origen: origin + this.currentSite?.type,
        mensajes: ""
      };

      this.sendTracking(trackingData);
    }, 1000);
  },

  props: {
    hideVideo: {
      type: Boolean,
      default: false
    },
    waitVideoSource: {
      type: String
    },
    waitVideoPoster: {
      type: String
    },
    onlyGenerate: {
      type: Boolean,
      default: false
    },
    tramiteName: {
      type: String,
      required: true
    },
    coorelation_key: {
      type: String
    },
    userInfo: {
      required: true
    },
    branchId: {
      type: String
    },
    queueId: {
      type: String
    },
    source: {
      type: String,
      required: true
    },
    withAnalitycExtraFields: {
      type: Boolean,
      default: false
    },
    withVideocall: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    async actualTurn(val) {
      // if (!this.audioNotification && val != "canceled") {
      document.getElementById("aproximate").currentTime = 0;
      await document.getElementById("aproximate").play();
      // }
    },

    stage(val) {
      if (val === 3) {
        this.$emit("endTurn");
        const timer = setTimeout(() => {
          this.goHome();
          clearTimeout(timer);
        }, 10);
      }
    },

    showModalCancel(value) {
      if (value) {
        const timer = setTimeout(() => {
          this.showModalCancel = false;
          this.cancelTurnAction();
          clearTimeout(timer);
        }, 4000);
      }
    },

    status(v) {
      this.turnPosition();

      // tracking
      setTimeout(() => {
        let origin = "fv_";
        if (location.hash.includes("fv_atril")) origin = "fv_atril_";
        let trackingData = {
          uuid: window.person.id,
          accion: "customer-cambio-status: " + v,
          debmedia_turn_code: this.turn.code,
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 1000);
    }
  },

  computed: {
    ...mapState({
      turn: state => state.turns.info,
      currentUser: state => state.virtualrow.currentUser,
      isAtril: state => state.virtualrow.isAtril,
      env: state => state.env,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      currentSite: state => state.virtualrow.currentSite,
      priority: (state) => state.virtualrow.priority
    }),

    comp() {
      const comp = this.env.VUE_APP_TEXT_COMPANY || this.env.VUE_APP_COMPANY;
      return comp.charAt(0).toUpperCase() + comp.slice(1);
    },

    videoSource() {
      return (
        this.waitVideoSource ||
        "https://bsix.assets.gobrilliant.com/colmedica/Colmedica_Promo_Video.mp4"
      );
    },

    videoPoster() {
      return (
        this.waitVideoPoster ||
        "https://bsix.assets.gobrilliant.com/colmedica/VideoColmedicaPoster.jpg"
      );
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteName() {
      return this.currentSite?.name;
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    infoOfTurn() {
      return {
        onlyGenerate: this.onlyGenerate,
        source: this.source,
        queueId:
          this.demoMode || !this.queueId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
            : this.queueId,
        branchId:
          this.demoMode || !this.branchId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
            : this.branchId,
        coorelation_key: this.coorelation_key,
        withVideocall: this.withVideocall,
        withAnalitycExtraFields: this.withAnalitycExtraFields,
        params: this.userInfo
      };
    },

    turnCommon() {
      return {
        myTurn: this.myTurn,
        actualTurn: this.actualTurn,
        tramiteName: this.tramiteName
      };
    },

    status() {
      return this.turn?.status;
    },

    actualTurn() {
      return this.turn?.jsonDetails?.actualTurn;
    },

    hasPriority() {
      return this.turn?.jsonDetails?.hasPriority;
    },

    myTurn() {
      return this.turn?.jsonDetails?.turn;
    },

    attentionModule() {
      return this.turn.worker?.name;
    },

    attentionAgent() {
      return `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this
        .turn.worker?.actualUser?.uUser?.lastName || ""}`.trim();
    }
  },

  methods: {
    ...mapActions({
      getTurn: "turns/getTurn",
      cancelTurnAction: "turns/cancelTurnAction",
      sendTracking: "virtualrow/handleTracking",
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack"
    }),
    cancelTurnHandler() {
      this.$emit("goBack");
    },
    actualTime() {
      const timer = setTimeout(() => {
        this.actualTime();
        clearTimeout(timer);
      }, 1000);
      let n = moment()
        .locale("es")
        .format("dddd DD [de] MMMM[,] hh:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
      return n[0].toUpperCase() + n.substring(1);
    },
    calculatedMinutes(turn) {
      return Math.ceil(turn.jsonDetails.averageWaitingTime);
    },
    async startTurn() {
      this.stage = 0;
      if (this.errorCounter > 3) this.errorCounter = 0;
      return this.getTurn(this.infoOfTurn)
        .then(turnInfo => {
          if (this.onlyGenerate)
            this.$emit("generatedTurn", {
              turnInfo,
              generationInfo: this.infoOfTurn
            });
        })
        .catch(error => {
          this.errorCounter++;
          console.error("Cant get turn for turn component", error);
          if (this.errorCounter < 3) setTimeout(() => this.startTurn(), 3000);
          else this.$emit("errorRequestTurn");
        });
    },

    goHome() {
      this.cancelTurnAction(true);
      const timer = setTimeout(() => {
        if (this.isAtril) this.$store.dispatch("setTimer");
        this.goToView({ view: "Home" });
        clearTimeout(timer);
      }, 200);
    },

    async turnAudioPlay() {
      if (this.audioNotification) {
        if (document.getElementById("attention")) {
          try {
            this.timerSound = setTimeout(() => {
              try {
                if (
                  document.getElementById("attention") &&
                  document.getElementById("attention").currentTime > 0
                )
                  return;
              } catch (error) {
                console.error("Invalid turn audio state", error);
              }
              this.soundEmmited();
            }, 2000);
            document.getElementById("attention").currentTime = 0;
            await document.getElementById("attention").play();
          } catch (error) {
            console.error("Sound of meeting cant play in turn component");
            this.soundEmmited();
          }
        } else {
          console.error("Sound tag not found", this.$refs);
          this.soundEmmited();
        }
      } else this.soundEmmited();
    },

    soundEmmited() {
      this.stage = 2;
    },

    turnPosition() {
      if (this.turn) {
        if (this.stage <= 0) {
          if (this.status === "WAITING_TO_BE_CALLED") this.stage = 1;
          else if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else this.stage = 3;
        } else if (this.stage === 1) {
          if (this.status === "ANNOUNCED") this.turnAudioPlay();
          else if (this.status !== "WAITING_TO_BE_CALLED") this.stage = 3;
        } else if (this.stage === 2) {
          if (
            this.status === "WAITING_TO_BE_CALLED" ||
            this.status === "ABSENT"
          )
            this.stage = 1;
          else if (this.status !== "ANNOUNCED") this.stage = 3;
        }
      } else {
        if (this.stage > 0) this.stage = 3;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dk-padd {
  padding-top: 15px;
  text-align: center;
}

.Container {
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.Content {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
  z-index: 1036;
}

.size_icon--atril {
  width: 373px;
}

.box-red {
  color: #055ba4;
  font-size: 25px;
  font-weight: 400;
  padding-left: 10px;
  border-left: 2px solid red;
  max-width: 1280px;
  margin: 0 auto !important;
  margin-bottom: 1.25rem !important;
  display: block;
}

.buttons {
  position: fixed;
  bottom: 40px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgba(223, 230, 237, 0);
  margin: auto;
  z-index: 1035;
  padding: 0 2rem;
  padding-top: 2rem;
}

.size_icon {
  width: 173px;
}

#video {
  width: 100%;
}

.font-lighter {
  line-height: 20px;
  font-size: 20px;
  text-align: center;
}

.size_icon-mobile {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.special-border {
  height: 45px;
  inset: -16px;
  background: #ff0000;
  position: absolute;
  border-radius: 10px 10px 0 0;
}

.size_icon-atril {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.text-modals {
  font-size: 24px;
  line-height: 26px;
}

.header-py {
  padding: 20px;
  margin: -20px;
  margin-bottom: 10px;
  border-radius: 20px 20px 0 0;
  background-color: #91c9ed;
}

.overlay-m-c {
  background-color: rgba(0, 0, 0, 0.56);
  inset: 0;
  position: fixed;
  z-index: 103000004;
  opacity: 0;
  animation: 0.5s forwards ease-in-out fadeIn;
}

.modal-content-c {
  opacity: 0;
  animation: 0.5s forwards ease-in-out fadeIn;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 103000005;
}

.modal-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 886px;
  height: 211px;
  background-color: #ff0f0f;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.box-flex-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 85px !important;
  margin-bottom: 85px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
